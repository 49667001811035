import React, { useState, useRef } from 'react';
import './App.css';

function App() {
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');
  const [question, setQuestion] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const textareaRef = useRef(null);
  const maxRows = 4; // Define el máximo número de filas permitido

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleInput = (e) => {
    if (textareaRef.current.scrollHeight > textareaRef.current.clientHeight) {
      if (textareaRef.current.rows < maxRows) {
        textareaRef.current.rows += 1; // Incrementa una fila adicional si no se alcanzó el máximo
      }
    }
    setMessage(e.target.value);
  };

  const handleSubmit = () => {
    if (!message) {
      return; // Si el mensaje está vacío, no hagas nada
    }

    setQuestion(message);
    setIsLoading(true);

    fetch('https://back.visioncloud.app/api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message })
    })
      .then((res) => res.json())
      .then((data) => {
        setResponse(data.message);
        setIsLoading(false);
        setIsFormSubmitted(true);
      });

    setMessage('');
    textareaRef.current.rows = 3; // Reinicia el tamaño del textarea después de enviar el mensaje
  };

  return (
    <main className='App'>
      <section>
        <h1 className='title more-text'>ChatGPT "without" filters (DAN)</h1>
      </section>
      <aside>
        <div>
          <form>
            <div>
              <textarea
                ref={ textareaRef }
                className='textarea'
                value={ message }
                placeholder="Ask ChatGPT whatever you want! >:)"
                onKeyDown={ handleKeyDown }
                onInput={ handleInput }
                style={ { maxHeight: `${maxRows * 25}px` } } // Define la altura máxima del textarea
              ></textarea>
            </div>
            <button
              className='button'
              type='button'
              onClick={ handleSubmit }
              disabled={ !message || isLoading } // Deshabilita el botón si no hay mensaje o si está cargando
            >
              { isLoading ? 'Loading...' : 'Submit' }
            </button>
          </form>
        </div>
      </aside>
      { isFormSubmitted && question && (
        <div><p className='question'><b>Q: </b> { question } </p></div>
      ) }
      <div className='response'>
        <h2>Answer "without" filter:</h2>
      </div>
      <div className='text'>{ isLoading && <p>Loading...</p> }  { response }</div>
    </main>
  );
}

export default App